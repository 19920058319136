@use './root.scss';

.container-about {
    min-height: 100vh;
    padding: 5vw 5vw;
    background-color: #ecebeb;
   
    @media screen and (min-width:"640px") and (max-width:"1399px") {
        padding: 7vw 5vw;

    }

    .wrapper-about {
        width: 100%;

        .main-about {
            display: flex;
            color: black;
            flex-direction: column;
            align-items: center;
            //margin-top: 2vw;

            label{
                font-size: 3.2vw;
                margin-bottom: 1vw ;
                animation: flyLeft 0.7s ease-in-out 0.3s forwards;
            }
           

            .dash {
                border: 1px;
                height: 5px;
                width: 2rem;
                background: #{root.$theme-color};
                border-radius: 3px;

            }
        }

        .about-content {
            display: grid;
            justify-content: center;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10rem;
            margin-top: 5rem;

            .grid-gap{
                display: flex;
                flex-direction: column; 
                gap: 1.5rem;
            }
            .about-details {

                h3 {
                    font-size: 2.3vw;
                }

                p {
                    text-align: justify;

                    color: #605f5f;
                    line-height: 1.7;
                    font-size: 1.5vw;
                }

                .resume-span {
                    background: #{root.$theme-color};
                    height: 2.5rem;
                    width: 12vw;
                    color: white;
                    border: 0;
                    border-radius: 5px;
                    font-size: 1.2rem;
                    font-weight: 600;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    min-width: 7rem;
                    max-width: 8rem;
                    transition: 0.3s;

                    a {
                        text-decoration: none;
                        color: whitesmoke;

                    }

                    &:hover {
                        box-shadow: 0px 0px 20px #{root.$theme-color};
                        cursor: pointer;

                    }

                }

            }

            .about-skills {

                margin: 0;

                h3 {
                    font-size: 2.3vw;
                }

                .skills {
                    display: flex;
                    flex-wrap: wrap;

                    .sub-skill {
                        background: lightgray;
                        width: fit-content;
                        padding: 0.5rem 1rem;
                        border-radius: 5px;
                        margin-bottom: 1.5rem;
                        margin-right: 1.5rem;
                        font-weight: 600;
                        color: #797979;
                        font-size: 1.5vw;
                    }
                }
            }




        }
        @media #{root.$iphone-portrait} {

            .main-about{
                margin-top: 10vw;
                label{
                    font-size: 2.5rem;
                    margin: 0 0 1vw 0;
                }
                .about-details {

                    p {
                        font-size: 3vw;
                    }

                }
            }

            .about-content {
                grid-template-columns: none;
                grid-template-rows: 1fr 1fr;
                grid-gap: 5rem;
                margin-top: 3rem;

                .about-details {
                    h3 {
                        font-size: 1.5rem;
                    }

                    .description {

                        p {
                            font-size: 1.1rem;
                        }
                    }

                    .resume-span {
                        
                        font-size: 1.1rem;
                        align-self: center;
                        
                        a {
                            text-decoration: none;
                            color: whitesmoke;

                        }
                    }


                }

                .about-skills{
                    h3{
                        font-size: 1.5rem;
                    }

                    .skills{

                        .sub-skill{
                            font-size: 1.1rem;
                            margin-bottom: 1.2rem;
                            margin-right: 1.2rem;
                            
                        }
                    }
                }


            }
            
        }

        @media #{root.$ipad-pro-portrait} {
            .main-about {
                margin-top: 6vw;

                label{
                    font-size: 5.2vw;
                    margin: 0 0 1vw 0;
                }


                .about-details {

                    p {
                        font-size: 3vw;
                    }

                }
            }

            .about-content {
                grid-template-columns: none;
                grid-template-rows: 1fr 1fr;
                grid-gap: 5rem;

                .about-details {
                    h3 {
                        font-size: 2rem;
                    }

                    .description {

                        p {
                            font-size: 1.3rem;
                        }
                    }

                    .resume-span {
                        
                        height: 3rem;
                        width: 12rem;
                        font-size: 1.1rem;
                        a {
                            text-decoration: none;
                            color: whitesmoke;

                        }
                    }


                }

                .about-skills{
                    h3{
                        font-size: 2rem;
                        letter-spacing: 1px;
                    }

                    .skills{

                        .sub-skill{
                            font-size: 1.2rem;
                            margin-bottom: 1.2rem;
                            margin-right: 1.2rem;
                            
                        }
                    }
                }

            }


        }
    }
}


@keyframes flyLeft {
    100% {
      left: -14px;
    }
  }
  