@use './root.scss';


.footer-container{
    width: 100%;
    height: fit-content;
    background-color: black;
    padding: 5vw 5vw;

    .wrapper-footer{

        .footer-grid{
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .t-content{
                display: flex;

               
        
            .left{
                width: 50%;
                .contact-details{
                    display: grid;
                    img{
                        padding: 10px;
                        width: 2.5rem;
                    }
                    label{
                        color: white;
                    }
                }
               
            }

            .right{
                width: 50%;
                align-items: end;
                display: flex;
                flex-direction: column;
            
                label{
                    margin-bottom: 2vw;
                }
                .flyer-wrapper{
                    text-align: center;
                    gap: 10px;
                    display: flex;

                
                    a{
                        .logo-size{
                            // text-align: center;
                            width: 1.5rem;
                            // background-color: azure;
                            
                
                        }
                    }
                }

            }
            @media #{root.$iphone-portrait} {

                display: grid;
                grid-template-rows: 1fr;
                gap: 2rem;
                .left{
                    width: 100%;
                }
                .right{
                    align-items: start;
                }

                
            }

            
        }
        .b-content{
            
            text-align: center;
            .custom-hr {
                border-top: 2px solid #cfd2d4; /* Change the color to your desired color */
            }
            hr{
                opacity: .50;
            }
            label{
                color: white;
                font-size: 0.8rem;
                
            }
        }
        }


    }
}