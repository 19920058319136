@use './root.scss';

.container-work {
    min-height: 100vh;
    padding: 5vw 5vw;
    background-color: #ecebeb9c;
    
    @media #{root.$ipad-pro-portrait} {
        padding: 5vh 2vh;
    }
    @media screen and (min-width:"640px") and (max-width:"1399px") {
        padding: 7vw 5vw;

    }

    .wrapper-work {
        width: 100%;
        .main-work {
            display: flex;
            color: black;
            flex-direction: column;
            align-items: center;
            //margin-top: 1vw;

            label{
                font-size: 3.2vw;
                margin-bottom: 1vw ;
            }

            .dash-work {
                border: 1px;
                height: 5px;
                width: 2rem;
                background:#{root.$theme-color};
                border-radius: 3px;
            }
        }

        .timeline {
            position: relative;
            max-width: 1200px;
            margin: 0 auto;
            width: 100%;

            &::after {
                content: '';
                width: 10px;
                background: #{root.$theme-color};
                display: block;
                top: 0;
                bottom: 0;
                left: 50%;
                
                position: absolute;
                border-radius: 5px;
                // z-index: -1;

                // @media #{Media.$ipad-pro-portrait},#{Media.$iphone-portrait},#{Media.$iphone-landscape} {
                //     left: 0%;
                // }
            }


            .timeline-wrapper {
                width: 50%;
                height: fit-content;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: rgb(247, 245, 245);
                    top: 28px;
                     border: 4px solid #{root.$theme-color};
                    z-index: 1;
                    right: -20px;
                }

                // @media #{Media.$ipad-pro-portrait},#{Media.$iphone-portrait},#{Media.$iphone-landscape} {
                //     width: 100%;
                //     &::before {
                //         left: 25px;
                //         border: 4px solid #{Media.$bg-color-2};
                //         border-width: 15px 15px 15px 0;
                //         border-color: transparent #{Media.$bg-color-2} transparent transparent;
                //     }

                // }
            }

            .left {
                left: 0;

                &::before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;
                    border-left: 15px solid #{root.$theme-color};
                    position: absolute;
                    top: 32px;
                    right: 25px;
                }
            }

            .right {
                left: 50%;

                &::before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;
                    border-right: 15px solid #{root.$theme-color};
                    position: absolute;
                    top: 32px;
                    left: 25px;
                }
                &::after{
                        left: -11px;
                    }
                    
            }

            .content {
                position: relative;
                border-radius: 5px;
                padding: 15px;
                background: white;
                box-shadow: 0px 0px 5px #{root.$theme-color};

                

            }
            .content-left-margin{
                margin: 30px 40px 30px 20px;

            }

            .content-right-margin{
                margin: 30px 20px 30px 40px;

            }
        }
        @media #{root.$ipad-pro-portrait} {
            .main-work {
                margin-top: 3vw;

                label{
                    font-size: 5.2vw;
                    margin: 4vw 0 1vw 0;
                }
            }
        }

    }

    @media #{root.$iphone-portrait} {
        .wrapper-work{

            .main-work{
                margin-top: 10vw;
                label{
                    font-size: 2.5rem;
                    margin: 0 0 1vw 0;
                }
            }

            .timeline{

                &::after{
                    left:8px;
                }

                .timeline-wrapper{
                     width: 100%;

            //         &::after{
            //             // right: 0;
            //             // left: -11px
            //         }
                }
                 .left {

                    &::before {
                        right: 0;
                        left: 35px;
                        top: 30px;

                        border-right: 15px solid #{root.$theme-color};
                        border-left: 0;

                    }
                 &::after{
                        left: -2px;
                    }
                    
            }

                .right{
                    left: 0;

                    &::before {
                        left: 35px;
                        top: 30px;
                    }

                     &::after{
                        left: -2px;
                    }
                }
                .content-left-margin, .content-right-margin{
                    margin: 30px 20px 30px 50px;
                }
                
             }
        }
    }
}
