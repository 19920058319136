@use './root.scss';


.container-contact {
   // height: 100vh;
    background: url('./Images/background.jpg');
    padding: 5vw 5vw;
    background-size: cover;
    @media screen and (min-width:"640px") and (max-width:"1399px") {
        padding: 7vw 5vw;

    }

    .wrapper-contact {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    
        .main-contact{
            display: flex;
            color: black;
            flex-direction: column;
            align-items: center;
           
            label{
               
                font-size: 3.2vw;
                margin-bottom: 1vw;

            }
            .dash-contact{
                border: 1px;
                height: 5px;
                width: 2rem;
                background: #{root.$theme-color};
                border-radius: 3px;
            }
        }

        .contact-form-container{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4vw 15vw;
            height: 100%;
            

            .contact-form{
                width: 100%;
                padding: 2vw;
                max-width: 30rem;
                min-height: 10vw;
                box-shadow: 0px 0px 5px #bbbbbb;
                border-radius: 5px;
                background: white;

                .row-details{
                    margin-top: 2vw;
                    gap: 1rem;

                    input{
                        border-radius: 5px;
                        height: 5.5vh;
                        padding: 0 5px;
                        border: 1px solid;
                        background: #e9e9e9;
                    }

                    textarea{
                        border: none;
                        border-radius: 5px;
                        font-size: 1rem;
                        height: 10rem;
                        min-height: 150px;
                        padding: 5px 10px;
                        resize: none;
                        border: 1px solid;
                        background: #e9e9e9;
                    }
                    button{
                        background: #{root.$theme-color};
                        height: 3vw;
                        min-height: 2rem;
                        max-height: 3rem;
                        min-width: fit-content;
                        width: 9vw;
                        max-width: 9rem;
                        color: white;
                        border: 0;
                        border-radius: 5px;
                        font-size: 1rem;
                        font-weight: 600;
                        transition: 0.3s;

        
                        &:hover{
                            box-shadow: 0px 0px 10px #{root.$theme-color};
                            cursor: pointer;
                        }

                        
                    }
                    label{
                        text-align: center;
                    }
    
                }
                .row{
                   --bs-gutter-x: 0; 
                   
                }
            }


        }
        @media #{root.$ipad-pro-portrait} {
            .main-contact {
                margin-top: 3vw;

                label{
                    font-size: 5.2vw;
                    margin: 4vw 0 1vw 0;
                }
            }
            .contact-form-container{
                height: 65%;
                width: 100%;
                padding: 2vw 10vw;
                max-width: 50rem;

                .contact-form{
                    label{
                        font-size: 1.7rem;
                    }
                    .row-details{


                        gap:2.3rem;
                        input{
                            height: 35px;
                        }
                        button{
                            height: 5vw;
                            width: 15vw;
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        @media #{root.$iphone-portrait} {
            .main-contact {
                margin-top: 10vw;

                label{
                    font-size: 2.5rem;
                    margin: 0 0 1vw 0;
                }

                
            }
            .contact-form-container{
                height: 75%;
                width: 100%;
                padding: 5vw 5vw;
                max-width: 50rem;

                .contact-form{
                    padding: 1.5rem;
                    label{
                        font-size: 1.7rem;
                    }
                    .row-details{


                        gap:2.3rem;
                        input{
                            height: 35px;
                        }
                        button{
                            height: 2rem;
                            width: 5rem;
                            font-size: 1rem;
                        }
                    }
                }
            }
            
        }

    }
}
