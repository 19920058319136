@use './root.scss';

.container-home {
    padding: 0 5vw;
    background: url('./Images/background.jpg');
    background-size: cover;

    .wrapper{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;

        .main-home{
            text-align: center;
            width: 80%;

            .flyer-wrapper{
                display: grid;
                width: 6vw;
                min-width: 5rem;
                text-align: center;
                position: absolute;
                gap: 15px;
                padding: 2vw 0;
                left: 0;
                top: 17vw;

                
                @media #{root.$iphone-landscape} {
                    top: 8rem;
                }
            
                a{
                    .logo-size{
                        
                        text-align: center;
                        width: 3vw;
                        min-width: 2rem;
                        max-width: 5rem;
                        transition: 0.3s;
                        border-radius: 50%;

                        &:hover{
                            transform: scale(1.1);
                            box-shadow: 0px 0px 20px rgb(121, 121, 121);
                        }
                    }
                    transform: translateY(100%);
                    opacity: 0;

                    
                }

                a:nth-of-type(1){
                    animation: slideUp 0.5s linear forwards;
                }
                a:nth-of-type(2){
                    animation: slideUp 0.5s linear 0.5s forwards;
                }
                a:nth-of-type(3){
                    animation: slideUp 0.5s linear .8s forwards;
                }
            }

            .title{
                text-align: center;
                display: grid;
                gap: 2vw;
                
                label, h3, .about-me {
                    opacity: 0;
                }
                
                label{
                    font-size: 3.5rem;
                    text-transform: uppercase;
                    font-weight: 800;
                    margin: 0;
                    
                    transform: translateY(-100%);
                    opacity: 0;
                    animation: slideDown 0.7s linear 0.6s forwards;
                    letter-spacing: 2px;
                    span{
                        color: #{root.$theme-color};
                    }

                }
                h3{
                    font-size: 1.5vw;
                    font-weight: 300;
                    opacity: 0;
                    animation: opacityChange 0.7s linear 0.6s forwards !important;
                }
    
                .about-me{
                    background: #B0578D;
                    height: 6vh;
                    width: 10vw;
                    color: white;
                    border: 0;
                    border-radius: 5px;
                    font-size: 1.2rem;
                    font-weight: 600;
                    min-width: 8rem;
                    max-width: 10rem;
                    max-height: 5rem;
                    min-height: 2.5rem;
                    display: grid;
                    justify-self: center;
                    transition: 0.3s;
                    transform: translateY(100%);
                    opacity: 0;
                    animation: slideUp 0.7s linear 0.5s forwards ;

                    cursor: pointer;
                    a{
                        color: white;
                        text-decoration: none;
                        align-self: center;
                        
                    }
                    
                    &:hover{
                        box-shadow: 0px 0px 20px #{root.$theme-color};
                        cursor: pointer;

                    }
                  
                    @media #{root.$ipad-pro-landscape} {
                        width: fit-content;
                    }
                    
    
                }
            }

            @media #{root.$ipad-pro-portrait} {
                width: 75vw;
                .title{
                    gap: 4vh;
                    label{
                        font-size: 2.5rem;
                    }
                    h3{
                        font-size: 1.4rem;
                        padding: 0px 6vw 0 6vw;
                    }
                    .about-me{
                        height: 2.5vh;
                        width: fit-content;
                        min-width: 7rem;
                        max-width: 12rem;
                        max-height: 4rem;
                        min-height:3rem;

                    }
                }

                .flyer-wrapper{
                    top: 28rem;
                    a{
                        .logo-size{
                            text-align: center;
                            width: 5vw;
                            min-width: 2rem;
                            max-width: 7rem;

                
                        }
                    }
                }
            }

            @media #{root.$iphone-portrait} {

                width: 100%;
                display: grid;
                align-items: center;
                justify-content: center;
                gap: 1.5rem;

                .title{
                    gap: 3vh;
                    label{
                        font-size: 2rem;
                    }
                   
                    h3{
                        font-size: 1rem;
                        padding: 0px 6vw 0 6vw;
                    }
                    .about-me{
                        height: 2.5vh;
                        width: fit-content;
                        min-width: 7rem;
                       
                        max-height: 4rem;
                        min-height:3rem;

                       
                    }
                    }
                    .flyer-wrapper{
                        // left: 7rem;
                        // bottom: -25%;
                        grid-auto-flow: column;
                        width: fit-content;
                        position: relative;
                        padding: 0;
                        justify-self: center;
                        top:0;
                        
                        a{
                            .logo-size{
                                text-align: center;
                                width: 5vw;
                                min-width: 2rem;
                                max-width: 7rem;
        
                    
                            }
                        }
                    }
            }
        }

       
    }
}
@keyframes slideUp {
   0%{
    opacity: .5;
   }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slideDown {
    
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes opacityChange {
    
    100% {
      opacity: 1;
    }
  }
  
  
  
  
  
  