@use './root.scss';

.open-Nav{
   // padding: 2vw 5vw !important;
    width: 100% !important;
    opacity: 1 !important;
   // display: none !important;
}

.background-container{
    width: 0;
    position: fixed;
    display: flex;
    justify-content: end;
    z-index: 110;
    right:0;
    padding: 0;
    overflow: hidden;
    background-color: #0c0a0a8f;
    opacity: 0;
    transition: 0.5s;

.phone-menu-container {
    width: 50%;
    background-color: #{root.$theme-color};
    height: 100vh;
    transition: 0.5s;
   
    display: flex;
    justify-content: center;
    opacity: 0.95;

    .phone-menu-wrapper{
        opacity: 1;
        display: flex;
        flex-direction: column;
        width: 10rem;
        gap: 20vw;
        align-items: end;
        
            img{
                width: 2rem;
                display: grid;
                justify-content: end;
                margin-top: 0.5rem;
                
            }
        

        .menu-options{
            display: grid;
            gap: 2rem;
            align-self: center;
            
            a{
                color: white;
                text-decoration: none;
                justify-self: center;
                font-weight: 600;
                &:hover{
                    color: #5f1341;
                    font-size: 1.2rem;
                    transition: 0.5s;
                }
            }
           
        }

    }
}
}