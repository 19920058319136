@use './root.scss';



.header-container {

    padding: 1vw 5vw;
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 100;
    height: 10vh;
    min-height: 3rem;
    max-height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;

   

    .head-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .name {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 1vw;

            label {
                font-size: 2.2rem;
                font-weight: 900;
                color: black;
               
                @media #{root.$iphone-portrait} {
                    font-size: 5vw;
            }
            }
            .logo{
                display: block;
                height: 6vh;
                cursor: pointer;
            }
        }

        .header {
            display: flex;
            gap: 4rem;
            justify-content: end;
            font-size: 1.2rem;
            font-weight: 700;
            text-align: center;
            height: 2rem;
            align-items: center;

            a {
                text-decoration: none;
                color: black;
                cursor: pointer;
                transition: 0.3s;

                &:hover {
                    color: #{root.$theme-color};
                    transform: scale(1.2);
                }
                
            }

            .current-active-page{
                color: #{root.$theme-color};
                border-bottom: 3px solid #{root.$theme-color};
                transform: scale(1.2);
            }

            .change-color{
                color: #{root.$theme-color};
            }
            
        }
        img{
            display: none;
        }


        @media #{root.$ipad-pro-portrait} {

            .name {
                label {
                    font-size: 1.7rem;
                }
            }

            .header {
                font-size: 1.1rem;
            }
        }
    }
    @media #{root.$iphone-portrait} {
        height: 7vh;
        min-height: 3rem;
        max-height: 6rem;

        .head-wrapper{

            .header{
                display: none;
            }
            img{
                width: 1.5rem;
                display: grid;
                justify-content: end;
            }
        }
    }

   
}

